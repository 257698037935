import React, { useState, useEffect, useRef } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { TweenMax } from 'gsap'
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks'
import { isMobile } from 'react-device-detect'
import TransitionLinkFade from '../TransitionLink/TransitionLinkFade'
import TransitionLinkInFade from '../TransitionLink/TransitionLinkInFade'

const HeaderSecondary = ({ section, menuColour }) => {
  const headerSecondary = useRef(null)
  const headerSecondaryNav = useRef(null)

  const { entry } = useTransitionState()
  const [menuColourClass, setMenuColourClass] = useState('text-white')
  const [has_SlideIn, setHas_SlideIn] = useState(false)
  const [hasWindow, setHasWindow] = useState(false)

  useEffect(() => {
    setHasWindow(typeof window !== 'undefined')
  }, [])

  useEffect(() => {
    setHas_SlideIn(entry.state.headerSecondary_SlideIn ? true : false)
  }, [entry.state.headerSecondary_SlideIn])

  useEffect(() => {
    if (menuColour === 'White') {
      setMenuColourClass('text-white')
    } else {
      setMenuColourClass('text-black')
    }
  }, [menuColour])

  useEffect(() => {
    if (has_SlideIn) {
      setTimeout(() => {
        TweenMax.to(headerSecondaryNav.current, 0.5, {
          y: 0,
          opacity: 1,
        })
      }, 500)
    }
  }, [has_SlideIn])

  useEffect(() => {
    const eventScroll = () => {
      if (window.scrollY >= window.innerHeight / 4) {
        TweenMax.to(headerSecondary.current, 0.5, {
          opacity: 0,
        })
      }
      if (window.scrollY < window.innerHeight / 4) {
        TweenMax.to(headerSecondary.current, 0.5, {
          opacity: 1,
        })
      }
    }
    window.addEventListener('scroll', eventScroll)
    return () => {
      window.removeEventListener('scroll', eventScroll)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          prismicGlobal {
            data {
              title
            }
          }
        }
      `}
      render={data => (
        <header
          ref={headerSecondary}
          className="absolute top-0 inset-x-0 pointer-events-none z-50"
        >
          <nav
            ref={headerSecondaryNav}
            style={{
              transform: `translate3d(0,${has_SlideIn ? `-100%` : 0},0)`,
              opacity: `${has_SlideIn ? 0 : 1}`,
            }}
            className="text-xl md:text-3xl 2xl:text-4xl py-4 md:py-5 lg:py-8 px-gs"
          >
            <div className="flex flex-wrap -ml-gs">
              <div className="pl-gs w-1/2">
                {hasWindow && isMobile ? (
                  <span className={menuColourClass}>
                    {data.prismicGlobal.data.title}
                  </span>
                ) : (
                  <TransitionLinkFade
                    className={`link pointer-events-auto ${menuColourClass}`}
                    to="/"
                    partiallyActive={true}
                  >
                    {data.prismicGlobal.data.title}
                  </TransitionLinkFade>
                )}
              </div>
              <div className="pl-gs m w-1/2 text-right md:text-left">
                <TransitionLinkInFade
                  className={`link pointer-events-auto ${menuColourClass}`}
                  to={`/${section === 'work' ? '' : section}`}
                  partiallyActive={true}
                  entry={{
                    state: {
                      headerPrimary_SlideIn: true,
                    },
                  }}
                >
                  Menu
                </TransitionLinkInFade>
              </div>
            </div>
          </nav>
        </header>
      )}
    />
  )
}

export default HeaderSecondary
