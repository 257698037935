import React, { useEffect, useRef, useState } from 'react'
import Img from 'gatsby-image'
import Swiper from 'swiper'

const MediaImageSlider = ({ items, orientation }) => {
  const sliderRef = useRef(null)
  const sliderNext = useRef(null)
  const sliderPrev = useRef(null)
  const sliderPagination = useRef(null)
  const [caption, setCaption] = useState(items[0].caption)

  useEffect(() => {
    new Swiper(sliderRef.current, {
      speed: 750,
      slidesPerView: 1,
      spaceBetween: 150,
      navigation: {
        nextEl: sliderNext.current,
        prevEl: sliderPrev.current,
      },
      pagination: {
        el: sliderPagination.current,
        type: 'fraction',
      },
      loop: true,
      on: {
        slideChange: function() {
          setCaption(items[this.activeIndex % 3].caption)
        },
      },
    })
  }, [items])

  return (
    <div className="md:py-24 xl:py-39">
      <div className="flex flex-wrap -ml-gs">
        <div
          className={`pl-gs hidden md:block ${
            orientation === 'Portrait' ? 'w-1/4' : 'w-1/12'
          }`}
        >
          <div className="w-full h-full flex items-center justify-start">
            <button
              ref={sliderPrev}
              className={`w-5 h-5 transition-opacity hover:opacity-75 focus:outline-none`}
            >
              <svg viewBox="0 0 44 44">
                <path d="M18.76,40.82l3.13-3.13L8.26,24.18H44V19.82H8.26L21.89,6.31,18.76,3.18,0,21.94v.12Z" />
              </svg>
            </button>
          </div>
        </div>
        <div
          className={`pl-gs w-full ${
            orientation === 'Portrait' ? 'md:w-2/4' : 'md:w-10/12'
          }`}
        >
          <div ref={sliderRef} className="w-full overflow-hidden">
            <div className="swiper-wrapper">
              {items &&
                items.map((sliderItem, index) => {
                  return (
                    <div key={index} className="swiper-slide">
                      <Img
                        fluid={sliderItem.image.fluid}
                        alt={sliderItem.image.alt}
                        // Set fadeIn to false to prevent images from failing to appear when duplicated for swiper looping
                        // Eager loading prevents images from flashing in on slide change
                        loading="eager"
                        fadeIn={false}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div
          className={`pl-gs hidden md:block ${
            orientation === 'Portrait' ? 'w-1/4' : 'w-1/12'
          }`}
        >
          <div className="w-full h-full flex items-center justify-end">
            <button
              ref={sliderNext}
              className={`w-5 h-5 transition-opacity hover:opacity-75 focus:outline-none`}
            >
              <svg viewBox="0 0 44 44">
                <path d="M25.24,3.18,22.11,6.31,35.74,19.82H0v4.36H35.74L22.11,37.69l3.13,3.13L44,22.06v-.12Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -ml-gs">
        <div
          className={`pl-gs w-full mx-auto ${
            orientation === 'Portrait' ? 'md:w-2/4' : 'md:w-10/12'
          }`}
        >
          <div className="flex flex-wrap -ml-gs pt-2">
            <div
              className="pl-gs w-1/6 md:w-1/10 static flex"
              ref={sliderPagination}
            ></div>
            <div className="pl-gs w-5/6 md:w-9/10">{caption}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaImageSlider
