import React from 'react'
import Img from 'gatsby-image'

const MediaImage = ({ image, imageAlt, className, caption }) => {
  if (!image.fluid) {
    return null
  }
  return (
    <>
      <Img
        fluid={image.fluid}
        alt={imageAlt}
        className={className}
        // prevent blur up placeholder
        placeholderStyle={{ display: 'none' }}
        loading="eager"
      />
      {caption && <span className="inline-block mt-1">{caption}</span>}
    </>
  )
}

export default MediaImage
