import React, { useState, useEffect } from 'react'
import Media from '../Media'

const MediaHero = ({ data, showVideoPoster, isIndexPage }) => {
  const [windowW, setWindowW] = useState(null)

  useEffect(() => {
    setWindowW(window.innerWidth)
  }, [])

  let image
  let video
  let poster

  // Media Options:
  // - Hero Image (landscape): hero_image
  // - Hero Image Portrait: hero_image_portrait
  // - Hero Video (landscape): hero_video_url
  // - Hero Video Portrait: hero_video_url_portrait
  // - Hero Video Poster (landscape): hero_video_poster
  // - Hero Video Poster Portrait: hero_video_poster_portrait

  if (windowW > 768) {
    image = data.hero_image
    video = data.hero_video_url
    poster = data.hero_video_poster
  } else {
    image = data.hero_image_portrait
      ? data.hero_image_portrait
      : data.hero_image
    video = data.hero_video_url_portrait
    poster = data.hero_video_poster_portrait
  }

  return (
    <Media
      image={image}
      videoUrl={video}
      videoPoster={poster}
      display={data.hero_display}
      showVideoPoster={showVideoPoster}
      className={`w-screen bg-black ${isIndexPage ? 'h-window' : 'h-screen'}`}
    />
  )
}

export default MediaHero
