import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Link } from 'gatsby'
import { isMobile } from 'react-device-detect'
import { TimelineMax } from 'gsap'

const TransitionOUT = ({ exit: { length, state }, node }) => {
  let scrollTop
  if (document.scrollingElement && document.scrollingElement.scrollTop) {
    scrollTop = document.scrollingElement.scrollTop
  } else {
    scrollTop = window.pageYOffset
  }
  const target = node.querySelector('.js-transition-link-target')

  new TimelineMax()
    .set(node, {
      overflowY: 'hidden',
      height: '100vh',
      scrollTop: scrollTop,
    })
    .fromTo(
      target,
      length,
      {
        opacity: 1,
      },
      {
        opacity: 0,
      }
    )
}

const TransitionIN = ({ entry: { length }, node }) => {
  const target = node.querySelector('.js-transition-link-target')
  new TimelineMax().fromTo(
    target,
    length,
    {
      opacity: 0,
    },
    {
      opacity: 1,
    }
  )
}

const TransitionLinkFade = props => {
  return isMobile ? (
    <Link className={props.className} to={props.to}>
      {props.children}
    </Link>
  ) : (
    <TransitionLink
      {...props}
      exit={{
        ...props.exit,
        length: 0.5,
        zIndex: 999,
        trigger: ({ exit, node }) => TransitionOUT({ exit, node }),
      }}
      entry={{
        ...props.entry,
        delay: 0.5,
        length: 0.5,
        trigger: ({ entry, node }) => TransitionIN({ entry, node }),
      }}
    >
      {props.children}
    </TransitionLink>
  )
}

export default TransitionLinkFade
