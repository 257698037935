import React from 'react'

const presentationalUrl = url => {
  return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
}

const WorkInfo = ({ pageData, toggleWorkInfo }) => {
  return (
    <div className="mb-8">
      <div className="flex flex-wrap -ml-gs">
        <div className="pl-gs w-1/2 md:hidden">
          <button
            className="block text-xl focus:outline-none"
            onClick={() => {
              toggleWorkInfo()
            }}
          >
            Close
          </button>
        </div>
        <div className="pl-gs w-1/2 mb-20 md:mb-0">
          {pageData.types && (
            <div className="text-sm 2xl:text-base">
              <h4 className="opacity-50">Scope of Work</h4>
              <ul className="mb-4 lg:mb-6">
                {pageData.types.map(
                  (item, index) =>
                    item.type.document && (
                      <li key={index}>{item.type.document.data.title}</li>
                    )
                )}
              </ul>
            </div>
          )}

          {pageData.website.raw.url != null && (
            <div className="text-sm 2xl:text-base">
              <h4 className="opacity-50">Website</h4>
              <a
                className="link block mb-4 lg:mb-6"
                href={pageData.website.raw.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {presentationalUrl(pageData.website.raw.url)}
              </a>
            </div>
          )}

          {pageData.awards.length > 0 && (
            <div className="text-sm 2xl:text-base">
              <h4 className="opacity-50">Awards</h4>
              {pageData.awards
                .filter(item => item.award)
                .map((item, index) => {
                  let awardStyle = {
                    backgroundColor: '#' + item.hex_colour,
                  }
                  return (
                    <div className="flex items-center" key={index}>
                      {item.hex_colour && (
                        <div
                          className="w-3 h-3 rounded-full mr-1"
                          style={awardStyle}
                        ></div>
                      )}
                      <span className="pt-1">{item.award}</span>
                    </div>
                  )
                })}
            </div>
          )}
        </div>
        {pageData.content.html && (
          <div className="pl-gs md:w-1/2">
            <div
              className="rte text-lg"
              dangerouslySetInnerHTML={{
                __html: pageData.content.html,
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  )
}

export default WorkInfo
