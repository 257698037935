import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Link } from 'gatsby'
import { TweenLite, TimelineMax } from 'gsap'
import { isMobile } from 'react-device-detect'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

// Do not remove, transition will break without this plugins array in scope
//eslint-disable-next-line
const plugins = [ScrollToPlugin]

const TransitionOUT = ({ exit: { length }, node }) => {
  const vh = window.innerHeight * 0.01
  const nodeHeight = node.getBoundingClientRect().height
  const fromHeight = nodeHeight
  const toHeight = nodeHeight + 70 * vh

  const target = document.querySelector('.js-work-item-next-project')
  const targetRect = target.getBoundingClientRect()
  const scrollTo = targetRect.top + window.pageYOffset

  setTimeout(() => {
    TweenLite.to(window, 0.95, {
      scrollTo: scrollTo,
    })
  }, 50)

  new TimelineMax().fromTo(
    node,
    1,
    {
      height: fromHeight,
    },
    {
      height: toHeight,
    }
  )
}

const TransitionIN = ({ entry: { length }, node }) => {
  window.scrollTo(0, 0)
}

const TransitionLinkWorkPagination = props => {
  return isMobile ? (
    <Link className={props.className} to={props.to}>
      {props.children}
    </Link>
  ) : (
    <TransitionLink
      {...props}
      exit={{
        ...props.exit,
        length: 1,
        zIndex: 111,
        trigger: ({ exit, node }) => TransitionOUT({ exit, node }),
      }}
      entry={{
        ...props.entry,
        length: 0.1,
        delay: 1,
        zIndex: 999,
        state: {
          headerSecondary_SlideIn: true,
        },
        trigger: ({ entry, node }) => TransitionIN({ entry, node }),
      }}
    >
      {props.children}
    </TransitionLink>
  )
}

export default TransitionLinkWorkPagination
