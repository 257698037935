import React, { useEffect, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import TransitionLinkInFade from '../TransitionLink/TransitionLinkInFade'
import { TweenLite, TweenMax } from 'gsap'

const WorkMenu = ({
  data,
  showWorkMain,
  showWorkInfo,
  toggleWorkInfo,
  uid,
  section,
}) => {
  const workMenu = useRef(null)

  const [fixedPosition, setFixedPosition] = useState(false)
  const [movementY, setMovementY] = useState(0)
  const [opacity, setOpacity] = useState(1)
  const [showInfoButton, setShowInfoButton] = useState(true)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [duration, setDuration] = useState(0)

  const getOffsetTop = element => {
    const rect = element.getBoundingClientRect()
    return rect.top + window.pageYOffset
  }

  useEffect(() => {
    const eventTick = () => {
      let _movementY
      if (movementY >= 0 && movementY <= 100) _movementY = movementY
      if (movementY > 100) _movementY = 100
      if (movementY < 0) _movementY = 0
      let _opacity
      if (opacity <= 1 && opacity >= 0) _opacity = opacity
      if (opacity > 1) _opacity = 1
      if (opacity < 0) _opacity = 0
      TweenMax.to(workMenu.current, duration, {
        y: `-${_movementY}%`,
        opacity: _opacity,
      })
    }

    const eventScroll = () => {
      const target = document.querySelector('.js-scroll-target')
      if (!target) return
      let scrollY = window.scrollY
      let windowH = window.innerHeight
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      let targetOffsetTop = getOffsetTop(target)
      let headerHeight = workMenu.current.getBoundingClientRect().height + 32
      if (scrollY >= windowH && !fixedPosition) {
        setFixedPosition(true)
      }
      if (scrollY < windowH && fixedPosition) {
        setFixedPosition(false)
      }
      // scrolling down...
      if (scrollTop > lastScrollTop) {
        if (scrollY <= targetOffsetTop) {
          setDuration(0)
          setMovementY(
            ((scrollY - (targetOffsetTop - headerHeight)) / headerHeight) * 100
          )
          setOpacity(
            1 -
              (scrollY - (targetOffsetTop - headerHeight)) / (headerHeight / 2)
          )
        } else {
          setDuration(0.5)
          setOpacity(0)
          setShowInfoButton(false)
          setMovementY(100)
          // setTimeout(()=> {
          //   setDuration(0)
          //   setMovementY(100)
          // }, 500);
        }
        // scrolling up...
      } else {
        setDuration(0.5)
        setOpacity(1)
        setMovementY(0)

        if (scrollY <= targetOffsetTop) {
          setShowInfoButton(true)
        }
      }
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop)
    }

    window.addEventListener('scroll', eventScroll)
    TweenLite.ticker.addEventListener('tick', eventTick)
    return () => {
      window.removeEventListener('scroll', eventScroll)
      TweenLite.ticker.removeEventListener('tick', eventTick)
    }
  }, [fixedPosition, movementY, opacity, lastScrollTop, duration])

  const handleMenuClick = () => {
    if (showWorkInfo) {
      document.documentElement.style.removeProperty('overflow', 'hidden')
    }
  }

  return (
    <div
      ref={workMenu}
      className={`top-0 inset-x-0 z-50 w-screen difference text-white ${
        fixedPosition ? 'fixed' : 'absolute'
      }`}
    >
      <div className="text-xl md:text-3xl 2xl:text-4xl px-gs py-8">
        <div className="flex flex-wrap -ml-gs">
          <div className="pl-gs w-full md:w-1/2">
            {data.title && <h1 className="">{data.title}</h1>}
          </div>
          <div className="pl-gs hidden md:block w-1/4">
            <TransitionLinkInFade
              to={`/${section === 'work' ? '' : section}`}
              entry={{
                state: {
                  headerPrimary_SlideIn: true,
                },
              }}
              onClick={() => {
                handleMenuClick()
              }}
              className="link"
            >
              Menu
            </TransitionLinkInFade>
          </div>
          <div className="pl-gs hidden md:block w-1/4">
            <CSSTransition
              in={showInfoButton}
              timeout={500}
              classNames="transition-work-menu-item"
              unmountOnExit
            >
              <button
                className="link relative focus:outline-none block"
                type="button"
                onClick={() => {
                  toggleWorkInfo()
                }}
              >
                <CSSTransition
                  in={showWorkMain}
                  timeout={500}
                  classNames="transition-work-menu-item"
                  unmountOnExit
                >
                  <span>Info</span>
                </CSSTransition>
                <CSSTransition
                  in={showWorkInfo}
                  timeout={500}
                  classNames="transition-work-menu-item"
                  unmountOnExit
                >
                  <span>Close</span>
                </CSSTransition>
              </button>
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkMenu
