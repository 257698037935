import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeaderSecondary from '../components/Header/HeaderSecondary'
import WorkMenu from '../components/Work/WorkMenu'
import WorkInfo from '../components/Work/WorkInfo'
import WorkSlices from '../components/Work/WorkSlices'
import MediaHero from '../components/Media/MediaHero'
import TransitionLinkWorkPagination from '../components/TransitionLink/TransitionLinkWorkPagination'
import { applyTheme } from '../utils/theme'
import scrollTo from '../utils/scrollTo'
import { Link } from 'gatsby'

const WorkItemTemplate = ({ location, data, pageContext }) => {
  const [showWorkMain, setShowWorkMain] = useState(true)
  const [showWorkInfo, setShowWorkInfo] = useState(false)
  const [showNextProjectDot, setShowNextProjectDot] = useState(false)

  useEffect(() => {
    applyTheme(pageContext.section === 'archive' ? 'dark' : 'light')
  }, [pageContext.section])

  useEffect(() => {
    if (location.state && location.state.fromWorkInfo) {
      window.scrollTo(0, window.innerHeight)
    }
  }, [location.state])

  const toggleWorkInfo = () => {
    if (showWorkMain) {
      scrollTo(window.innerHeight)
      setShowWorkMain(false)
      setTimeout(() => {
        setShowWorkInfo(true)
        document.documentElement.style.setProperty('overflow', 'hidden')
      }, 500)
    } else {
      setShowWorkInfo(false)
      document.documentElement.style.removeProperty('overflow', 'hidden')
      setTimeout(() => {
        setShowWorkMain(true)
      }, 500)
    }
  }

  const currentProjectUid = data.currentProject.uid
  const currentProjectData = data.currentProject.data
  const nextProjectUid = data.nextProject.uid
  const nextProjectData = data.nextProject.data
  const isArchivedWork = pageContext.section === 'archive'

  return (
    <Layout location={location}>
      <SEO
        title={currentProjectData.meta_title || 'Work Detail'}
        description={currentProjectData.meta_description || ''}
        location={location}
      />

      <div className="js-transition-link-target">
        <HeaderSecondary
          isArchivedWork={isArchivedWork}
          menuColour={currentProjectData.menu_colour}
          location={location}
          section={pageContext.section}
        />

        <MediaHero data={currentProjectData} />

        <div className="relative text-theme-a bg-theme-b">
          <WorkMenu
            data={currentProjectData}
            uid={currentProjectUid}
            section={pageContext.section}
            showWorkInfo={showWorkInfo}
            showWorkMain={showWorkMain}
            toggleWorkInfo={toggleWorkInfo}
          />

          <div
            className={`px-gs min-h-screen pt-60vh${
              showWorkInfo
                ? ' overflow-y-scroll max-h-screen scrolling-touch'
                : ''
            }`}
          >
            <div className="relative js-scroll-target">
              <CSSTransition
                in={showWorkInfo}
                addEndListener={(node, done) => {
                  node.addEventListener('transitionend', done, false)
                }}
                classNames="transition-content"
                unmountOnExit
              >
                <div className="top-0 inset-x-0">
                  <WorkInfo
                    pageData={currentProjectData}
                    toggleWorkInfo={toggleWorkInfo}
                  />
                </div>
              </CSSTransition>
              {currentProjectData.body && (
                <CSSTransition
                  in={showWorkMain}
                  addEndListener={(node, done) => {
                    node.addEventListener('transitionend', done, false)
                  }}
                  classNames="transition-content"
                  unmountOnExit
                >
                  <div className="top-0 inset-x-0">
                    <Link
                      to={`/${pageContext.section}/${currentProjectUid}/info`}
                      className="link block md:hidden text-xl mb-4"
                      state={{ fromWorkItem: true }}
                    >
                      Info
                    </Link>
                    <WorkSlices blocks={currentProjectData.body} />
                  </div>
                </CSSTransition>
              )}
            </div>
          </div>
        </div>

        {nextProjectData && showWorkMain && (
          <React.Fragment>
            <div className="text-sm 2xl:text-base pb-12 mt-20 md:mt-32 lg:mt-48 xl:mt-64">
              <div className="px-gs">
                <div className="flex flex-wrap -ml-gs">
                  <div className="pl-gs w-1/2 lg:w-1/4">
                    <TransitionLinkWorkPagination
                      onMouseEnter={() => setShowNextProjectDot(true)}
                      onMouseLeave={() => setShowNextProjectDot(false)}
                      to={`/${pageContext.section}/${nextProjectUid}`}
                    >
                      Next Project
                    </TransitionLinkWorkPagination>
                  </div>
                  {nextProjectData.title && (
                    <div className="pl-gs w-1/2 lg:w-3/4">
                      <TransitionLinkWorkPagination
                        onMouseEnter={() => setShowNextProjectDot(true)}
                        onMouseLeave={() => setShowNextProjectDot(false)}
                        to={`/${pageContext.section}/${nextProjectUid}`}
                      >
                        {nextProjectData.title}
                      </TransitionLinkWorkPagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="work-item-next-project js-work-item-next-project">
              <div className="work-item-next-project__inner">
                <TransitionLinkWorkPagination
                  className="relative"
                  onMouseEnter={() => setShowNextProjectDot(true)}
                  onMouseLeave={() => setShowNextProjectDot(false)}
                  to={`/${pageContext.section}/${nextProjectUid}`}
                >
                  <CSSTransition
                    in={showNextProjectDot}
                    timeout={300}
                    classNames="quick-fade"
                    unmountOnExit
                  >
                    <div
                      className={`absolute top-0 left-0 w-4 h-4 rounded-full z-20 mt-gs ml-gs ${
                        nextProjectData.menu_colour === 'Black'
                          ? 'bg-black'
                          : 'bg-white'
                      }`}
                    ></div>
                  </CSSTransition>
                  <div className="relative w-full h-full z-10">
                    <MediaHero data={nextProjectData} showVideoPoster />
                  </div>
                </TransitionLinkWorkPagination>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Layout>
  )
}

WorkItemTemplate.propTypes = {
  location: PropTypes.object.isRequired,
}

export const workItemTemplateQuery = graphql`
  query workItemByUid($uid: String, $nextProjectUid: String) {
    nextProject: prismicWork(uid: { eq: $nextProjectUid }) {
      uid
      data {
        title
        menu_colour
        hero_display
        hero_video_url
        hero_video_poster {
          url
        }
        hero_image {
          alt
          fluid(
            imgixParams: { q: 100 }
            srcSetBreakpoints: [640, 768, 1024, 1366, 1600, 1920, 2500]
          ) {
            srcSetWebp
            srcWebp
            sizes
            src
            srcSet
            base64
            aspectRatio
          }
        }
        hero_video_url_portrait
        hero_video_poster_portrait {
          url
        }
        hero_image_portrait {
          alt
          fluid(
            imgixParams: { q: 100 }
            srcSetBreakpoints: [640, 768, 1024, 1366, 1600, 1920, 2500]
          ) {
            srcSetWebp
            srcWebp
            sizes
            src
            srcSet
            base64
            aspectRatio
          }
        }
      }
    }
    currentProject: prismicWork(uid: { eq: $uid }) {
      uid
      data {
        meta_title
        meta_description
        hero_display
        hero_video_url
        hero_video_poster {
          url
        }
        hero_image {
          alt
          fluid(
            imgixParams: { q: 100 }
            srcSetBreakpoints: [640, 768, 1024, 1366, 1600, 1920, 2500]
          ) {
            srcSetWebp
            srcWebp
            sizes
            src
            srcSet
            base64
            aspectRatio
          }
        }
        hero_video_url_portrait
        hero_video_poster_portrait {
          url
        }
        hero_image_portrait {
          alt
          fluid(
            imgixParams: { q: 100 }
            srcSetBreakpoints: [640, 768, 1024, 1366, 1600, 1920, 2500]
          ) {
            srcSetWebp
            srcWebp
            sizes
            src
            srcSet
            base64
            aspectRatio
          }
        }
        title
        menu_colour
        types {
          type {
            document {
              ... on PrismicWorkType {
                data {
                  title
                }
              }
            }
          }
        }
        awards {
          award
          hex_colour
        }
        website {
          raw
        }
        content {
          html
        }
        body {
          ... on PrismicWorkBodyHalfImage {
            slice_type
            primary {
              image_alignment
              caption
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1366, 1600]
                ) {
                  srcSetWebp
                  srcWebp
                  sizes
                  src
                  srcSet
                  base64
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicWorkBodyFullImage {
            slice_type
            primary {
              caption
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1366, 1600, 1920, 2500]
                ) {
                  srcSetWebp
                  srcWebp
                  sizes
                  src
                  srcSet
                  base64
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicWorkBodySlider {
            id
            slice_type
            primary {
              image_orientation
            }
            items {
              caption
              image {
                fluid(
                  imgixParams: { q: 100 }
                  srcSetBreakpoints: [640, 768, 1024, 1366, 1600]
                ) {
                  srcSetWebp
                  srcWebp
                  sizes
                  src
                  srcSet
                  base64
                  aspectRatio
                }
              }
            }
          }
          ... on PrismicWorkBodyVideo {
            id
            slice_type
            primary {
              video_url
              video_poster {
                url
              }
            }
          }
          ... on PrismicWorkBodyHalfVideo {
            id
            slice_type
            primary {
              video_url
              video_poster {
                url
              }
              video_alignment
            }
          }
        }
      }
    }
  }
`

export default WorkItemTemplate
