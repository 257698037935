import React from 'react'

const MediaVideo = ({ videoUrl, poster, showVideoPoster, className }) => {
  if (showVideoPoster) {
    if (poster && poster.url) {
      return (
        <img
          className="w-full h-full object-cover"
          src={poster.url}
          alt="McCarthy"
        />
      )
    } else {
      return <div className="w-full h-full bg-black" />
    }
  } else {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: `
        <video
          src="${videoUrl}"
          poster="${poster && poster.url ? poster.url : ''}"
          class="w-full h-full object-cover"
          muted
          loop
          playsInline
          autoPlay
        />
      `,
        }}
      />
    )
  }
}

export default MediaVideo
