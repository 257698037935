import React from 'react'
import MediaImage from './MediaImage'
import MediaVideo from './MediaVideo'

const Media = ({
  image,
  imageAlt,
  videoUrl,
  videoPoster,
  display,
  showVideoPoster,
  className,
}) => {
  if (
    display === 'Image' ||
    (display === 'Video' && !videoUrl) ||
    display === null
  )
    return <MediaImage image={image} alt={imageAlt} className={className} />

  if (display === 'Video')
    return (
      <MediaVideo
        videoUrl={videoUrl}
        poster={videoPoster}
        showVideoPoster={showVideoPoster}
        className={className}
      />
    )

  return null
}

export default Media
