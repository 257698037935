import React from 'react'
import MediaImage from '../Media/MediaImage'
import MediaImageSlider from '../Media/MediaImageSlider'
import MediaVideo from '../Media/MediaVideo'

const WorkSlicesType = ({ sliceType, item, index, width }) => {
  if (!sliceType) return null

  function renderWorkSlicesType(sliceType) {
    switch (sliceType) {
      case 'full_image':
      case 'half_image':
        return (
          <div className={`pl-gs mb-8 md:mb-10 xl:mb-12 ${width}`}>
            <MediaImage
              image={item.primary.image}
              imageAlt={item.primary.image.alt}
              caption={item.primary.caption}
            />
          </div>
        )
      case 'video':
      case 'half_video':
        return (
          <div className={`pl-gs mb-8 md:mb-10 xl:mb-12 ${width}`}>
            <MediaVideo
              videoUrl={item.primary.video_url}
              poster={item.primary.video_poster}
              key={index}
            />
          </div>
        )
      case 'slider':
        return (
          <div className={`pl-gs mb-8 md:mb-10 xl:mb-12 ${width}`}>
            <MediaImageSlider
              items={item.items}
              key={index}
              orientation={item.primary.image_orientation}
            />
          </div>
        )
      default:
        return null
    }
  }
  return renderWorkSlicesType(sliceType)
}

const WorkSlices = ({ blocks }) => {
  return (
    <div className="flex flex-wrap items-end -ml-gs">
      {blocks.map((item, index) => {
        let alignment
        let width =
          item.slice_type === 'half_image' || item.slice_type === 'half_video'
            ? 'w-full md:w-1/2'
            : 'w-full'

        if (item.slice_type === 'half_image') {
          alignment =
            item.primary.image_alignment && item.primary.image_alignment
        } else if (item.slice_type === 'half_video') {
          alignment =
            item.primary.video_alignment && item.primary.video_alignment
        }
        return (
          <React.Fragment key={index}>
            {alignment === 'Right Alone' && (
              <div className="pl-gs hidden md:block w-1/2"></div>
            )}
            <WorkSlicesType
              sliceType={item.slice_type}
              item={item}
              index={index}
              width={width}
            />
            {alignment === 'Left Alone' && (
              <div className="pl-gs hidden md:block w-1/2"></div>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default WorkSlices
