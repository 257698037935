const scrollTo = target => {
  Math.easeInOutQuad = function(t, b, c, d) {
    t /= d / 2
    if (t < 1) {
      return (c / 2) * t * t + b
    }
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  let offsetTop
  offsetTop = target
  const move = amount => {
    document.documentElement.scrollTop = amount
    document.body.parentNode.scrollTop = amount
    document.body.scrollTop = amount
  }
  const position = () => {
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    )
  }
  let start = position()
  let change = offsetTop - start
  let currentTime = 0
  let increment = 20
  let duration = 1000
  let animateScroll = () => {
    currentTime += increment
    var val = Math.easeInOutQuad(currentTime, start, change, duration)
    move(val)
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll)
    }
  }
  animateScroll()
}

export default scrollTo
